export const IMG = {
  HeaderLogo: require("../assets/images/header/header-logo.png"),
  HomeBannerBg: require("../assets/images/home/home-begin-full.png"),
  HomeBannerBanner: require("../assets/images/home/home-begin-full-banner.png"),
  HomeBgEffect: require("../assets/images/home/home-bg-effect.png"),
  NetworkSlider1: require("../assets/images/home/network-slider1.png"),
  NetworkSlider2: require("../assets/images/home/network-slider2.png"),
  NetworkSlider3: require("../assets/images/home/network-slider3.png"),
  NetworkBg: require("../assets/images/home/newtwork-bg.png"),
  NetworkSpanBg: require("../assets/images/home/newtwork-span-bg.png"),
  HomeLogisticServices: require("../assets/images/home/home-logistic-services.png"),
  HomeLogisticServices2: require("../assets/images/home/home-logistic-services2.png"),
  EcomService1: require("../assets/images/home/ecom-service1.png"),
  EcomService2: require("../assets/images/home/ecom-service2.png"),
  EcomService3: require("../assets/images/home/ecom-service3.png"),
  EcomService4: require("../assets/images/home/ecom-service4.png"),
  EcomService5: require("../assets/images/home/ecom-service5.png"),
  EcomService6: require("../assets/images/home/ecom-service6.png"),
  Bullet: require("../assets/images/home/home-bullet.png"),
  BulletWhite: require("../assets/images/home/home-bullet-white.png"),
  HomeDamageFreeMen: require("../assets/images/home/home-damage-free-men.png"),
  HomeDamageFreeBgDart: require("../assets/images/home/home-damage-bg-dart.png"),
  HomeDamageFreeTruck: require("../assets/images/home/home-damage-free-truck.png"),
  HomeMarquee1: require("../assets/images/home/home-marquee1.png"),
  HomeMarquee2: require("../assets/images/home/home-marquee2.png"),
  HomeMarquee3: require("../assets/images/home/home-marquee3.png"),
  HomeMarquee4: require("../assets/images/home/home-marquee4.png"),
  HomeMarquee5: require("../assets/images/home/home-marquee5.png"),
  BenefitRightBg: require("../assets/images/home/benefits-right-bg.png"),
  CheckBullet: require("../assets/images/home/chaeck-bullet.png"),
  HomeCounter1: require("../assets/images/home/home-counter1.png"),
  HomeCounter2: require("../assets/images/home/home-counter2.png"),
  HomeCounter3: require("../assets/images/home/home-counter3.png"),
  HomeCounter4: require("../assets/images/home/home-counter4.png"),
  MomeProcessImg1: require("../assets/images/home/home-process-img1.png"),
  MomeProcessImg2: require("../assets/images/home/home-process-img2.png"),
  MomeProcessImg3: require("../assets/images/home/home-process-img3.png"),
  HomeEnsureRightBg: require("../assets/images/home/home-ensure-right-bg.png"),
  HomeEnsureRightImg: require("../assets/images/home/home-ensure-right-img.png"),
  LogisticBlogMainImg: require("../assets/images/home/logistic-blog-main-img.png"),
  LogisticBlogSliderImg1: require("../assets/images/home/logistic-blog-slider-img1.png"),
  TestimonialHeadBg: require("../assets/images/home/testimonail-text-bg.png"),
  TestimonailSliderMainImg: require("../assets/images/home/testimonail-slider-main-img.png"),
  TestimonailSliderMainImgBg: require("../assets/images/home/testimonail-slider-main-img-bg.png"),
  TestimonialSliderBgImg1: require("../assets/images/home/testimonail-slider-bg-img1.png"),
  TestimonialSliderBgImg2: require("../assets/images/home/testimonail-slider-bg-img2.png"),
  TestimonialSliderBgImg3: require("../assets/images/home/testimonail-slider-bg-img3.png"),
  TestimonialSliderBgImg4: require("../assets/images/home/testimonail-slider-bg-img4.png"),
  TestimonialSliderBgImg5: require("../assets/images/home/testimonail-slider-bg-img5.png"),
  TestimonialSliderBgImg6: require("../assets/images/home/testimonail-slider-bg-img6.png"),
  CalculatorBannerBg: require("../assets/images/fulfillCalculator/calculator-banner-bg.png"),
  CalculatorBannerImg: require("../assets/images/fulfillCalculator/calculator-banner-img.png"),
  CalcAffordableRightImg: require("../assets/images/fulfillCalculator/calc-affordable-right-img.png"),
  WhoWeAreBanner: require("../assets/images/who-we-are/who-we-are-banner.png"),
  WhoWeAreBannerBg: require("../assets/images/who-we-are/who-we-are-banner-bg.png"),
  WhoWeAreExperienceTriangle: require("../assets/images/who-we-are/who-we-are-experience-triangle.png"),
  WhoWeAreExperienceMainImg: require("../assets/images/who-we-are/who-we-are-experience-main-img.png"),
  AboutLogisticOpImg: require("../assets/images/who-we-are/about-logistic-operation-img.png"),
  AboutSpanDownBg: require("../assets/images/who-we-are/about-span-down-bg.png"),
  AboutCardIcon1: require("../assets/images/who-we-are/about-card-icon1.png"),
  AboutCardIcon2: require("../assets/images/who-we-are/about-card-icon2.png"),
  AboutCardIcon3: require("../assets/images/who-we-are/about-card-icon3.png"),
  AboutTestimonialBg: require("../assets/images/who-we-are/about-testimonial-bg.png"),
  AboutTestimonialAvatar: require("../assets/images/who-we-are/about-testimonial-avatar.png"),
  AboutTestimonialAvatar2: require("../assets/images/who-we-are/about-testimonial-avatar2.png"),
  AboutTestimonialAvatar3: require("../assets/images/who-we-are/about-testimonial-avatar3.png"),
  AboutHeadLine: require("../assets/images/who-we-are/about-head-line.png"),
  AboutEnsureBg: require("../assets/images/who-we-are/about-ensure-bg.png"),
  AboutEnsureRightImg: require("../assets/images/who-we-are/about-ensure-right-img.png"),
  HomeEnsureRightImg2: require("../assets/images/home/home-ensure-right-img2.png"),
  HomeDamageShield: require("../assets/images/home/home-damage-shield.png"),
  HomeOurprocessBg: require("../assets/images/home/our-process-bg.png"),
  HomeProcessFlag1: require("../assets/images/home/home-process-flag1.png"),
  HomeProcessFlag2: require("../assets/images/home/home-process-flag2.png"),
  ServiceBannerImg: require("../assets/images/services/services-banner-img.png"),
  ServicesAbout: require("../assets/images/services/services-about-img.png"),
  ServiceDottedImg1: require("../assets/images/services/services-dotted-img1.png"),
  ServiceDottedImg2: require("../assets/images/services/services-dotted-img2.png"),
  ServiceDottedImg3: require("../assets/images/services/services-dotted-img3.png"),
  ServiceDottedImg4: require("../assets/images/services/services-dotted-img4.png"),
  ServicesEveryBg: require("../assets/images/services/services-every-bg.png"),
  ServiceProcessInnerBox: require("../assets/images/services/services-procee-inner-box-img.png"),
  ServiceDeskImg: require("../assets/images/services/service-desk-img.png"),
  ServiceWarehouseBanner: require("../assets/images/services/services-warehouse-banner.png"),
  AboutWarehousingLeftImg: require("../assets/images/services/about-warehousing-left-img.png"),
  WarehousingTabImg1: require("../assets/images/services/warehousing-tab-img1.png"),
  BLogBannerImg: require("../assets/images/blog/blog-banner-img.png"),
  BlogCard1: require("../assets/images/blog/blog-card1.png"),
  BlogCard2: require("../assets/images/blog/blog-card2.png"),
  BlogCard3: require("../assets/images/blog/blog-card3.png"),
  AmazonPrepServicesBanner: require("../assets/images/services/amazone-prep-services-banner.png"),
  AmazoneServicesAbout: require("../assets/images/services/amazone-services-about.png"),
  AmazoneServicesMainAbout: require("../assets/images/services/amazone-services-main-about.png"),
  AmazoneCompleteImg: require("../assets/images/services/amazone-complete-banner.png"),
  AmazoneTabImg: require("../assets/images/services/amazone-tab-img.png"),
  AmazonePartnerBg: require("../assets/images/services/amazone-partner-bg.png"),
  AmazoneBenefitsImg1: require("../assets/images/services/amazon-benefits-img1.png"),
  AmazoneBenefitsImg2: require("../assets/images/services/amazon-benefits-img2.png"),
  WhyTitanBg: require("../assets/images/services/why-titan-bg.png"),
  WhyTitanImg: require("../assets/images/services/why-titan-img.png"),
  WalmartOfferImg1: require("../assets/images/services/walmart-offer-img1.jpg"),
  WalmartOfferImg2: require("../assets/images/services/walmart-offer-img2.jpg"),
  WalmartDartbg: require("../assets/images/services/walmart-dart-bg.png"),
  WalmartBanner: require("../assets/images/services/walmart-banner.png"),
  WalmartSeamlessLeft: require("../assets/images/services/walmart-seamless-left.jpg"),
  WalmartTabContentImg1: require("../assets/images/services/walmart-tab-content-img1.jpg"),
  IntegrationBanner: require("../assets/images/services/integration-banner.png"),
  IntegrationAbout: require("../assets/images/services/integration-about-img.jpg"),
  ShopifyLogo: require("../assets/images/services/shopify-logo.png"),
  AmazoneLogo: require("../assets/images/services/amazone-logo.png"),
  WooLogo: require("../assets/images/home/woo-logo.png"),
  EbayLogo: require("../assets/images/home/ebay-logo.png"),
  OpencartLogo: require("../assets/images/home/opencart-logo.png"),
  QbLogo: require("../assets/images/home/qb-logo.png"),
  PaypalLogo: require("../assets/images/home/paypal-logo.png"),
  EstyLogo: require("../assets/images/home/esty-logo.png"),
  WalmartLogo: require("../assets/images/home/walmart-logo.png"),
  BigcommerceLogo: require("../assets/images/home/bigcommerce-logo.png"),
  CommercehubLogo: require("../assets/images/home/commercehub-logo.png"),
  HomeBannerBgNew: require("../assets/images/home/home-banner-bg-new.jpg"),
  CalcEnterImg: require("../assets/images/fulfillCalculator/calc-enter-img.png"),
  HomeSearchIcon: require("../assets/images/home/home-search-Icon.png"),
  HomeTimelineImg: require("../assets/images/home/home-timeline-img.png"),
  Tshirt: require("../assets/images/products/t-shirt.png"),
  SunGlasses: require("../assets/images/products/sunglasses.png"),
  Vitamin: require("../assets/images/products/vitamin.png"),
  CoffeMug: require("../assets/images/products/coffe-mug.png"),
  Shoes: require("../assets/images/products/shoes.png"),
  Watch: require("../assets/images/products/watch.png"),
  Laptop: require("../assets/images/products/laptop.png"),
  Headphones: require("../assets/images/products/headphones.png"),
  Phone: require("../assets/images/products/phone.png"),
  Bottle: require("../assets/images/products/bottle.png"),
  Earbuds: require("../assets/images/products/earbuds.png"),
  PaperTowel: require("../assets/images/products/paper-towel.png"),
  PrivacyBanner: require("../assets/images/home/brivacy-banner.png"),
  FullFilmentProcess: require("../assets/images/services/fullfilment-process.png"),
  WarehousingWhyUs: require("../assets/images/services/warehousing-why-us.png"),
  AmazoneTabNew: require("../assets/images/services/AmazoneTabNew.jpg"),
  UseWalmart: require("../assets/images/services/use-walmart.jpg"),
  MagentoLogo: require("../assets/images/home/magento-logo.png"),
  EbayLogo2: require("../assets/images/home/ebay-logo2.png"),
  EstyLogo2: require("../assets/images/home/etsy-logo2.png"),
  FedexLogo: require("../assets/images/home/fedex-logo.png"),
  DhlLogo: require("../assets/images/home/dhl.png"),
  UpsLogo: require("../assets/images/home/ups-logo.png"),
  UspsLogo: require("../assets/images/home/usps-logo.png"),
  LogisticLogo: require("../assets/images/home/logistic-logo.png"),
  OurMilstone: require("../assets/images/home/our-milestone.png"),
  HomeAboutNew: require("../assets/images/home/home-about-new.png"),
  Netsuite: require("../assets/images/home/netsuite.png"),
  TradegeckoLogo: require("../assets/images/home/tradegecko.png"),
  BlueCherry: require("../assets/images/home/bluecherry.png"),
  LogicBroker: require("../assets/images/home/logicbroker.png"),
  Skubana: require("../assets/images/home/skubana.png"),
  Squarespace: require("../assets/images/home/squarespace.png"),
  Kwi: require("../assets/images/home/kwi.png"),
  Returnly: require("../assets/images/home/returnly.png"),
  Cgs: require("../assets/images/home/cgs.png"),
  Loop: require("../assets/images/home/loop.png"),
  Sps: require("../assets/images/home/sps.png"),
  Brightpearl: require("../assets/images/home/brightpearl.png"),
  Dsco: require("../assets/images/home/dsco.png"),
  Spring: require("../assets/images/home/spring.png"),
  Aims: require("../assets/images/home/aims.png"),
  Zedonk: require("../assets/images/home/zedonk.png"),
  Sage: require("../assets/images/home/sage.png"),
  Cegid: require("../assets/images/home/cegid.png"),
  WalmartLogo: require("../assets/images/home/walmart.png"),
  FlipkartLogo: require("../assets/images/home/flipkart.png"),
  FacebookLogo: require("../assets/images/home/facebook.png"),
  InstagramLogo: require("../assets/images/home/instagram.png"),
  DhlEcom: require("../assets/images/home/dhl-ecom.png"),
  FtlLogo: require("../assets/images/home/ftl-logo.png"),
  LtlLogo: require("../assets/images/home/ltl-logo.png"),
  PricingBanner: require("../assets/images/home/pricing-banner.png"),
  StorageNeeds: require("../assets/images/home/storage-needs.png"),
  StorageNeed1: require("../assets/images/home/storage-need1.png"),
  StorageNeed2: require("../assets/images/home/storage-need2.png"),
  StorageNeed3: require("../assets/images/home/storage-need3.png"),
  StorageNeed4: require("../assets/images/home/storage-need4.png"),
  StorageNeed5: require("../assets/images/home/storage-need5.png"),
  StorageNeed6: require("../assets/images/home/storage-need6.png"),
  HomeTimelineIcon1: require("../assets/images/home/home-timeline-icon1.png"),
  HomeTimelineIcon2: require("../assets/images/home/home-timeline-icon2.png"),
  HomeTimelineIcon3: require("../assets/images/home/home-timeline-icon3.png"),
  HomeTimelineIcon4: require("../assets/images/home/home-timeline-icon4.png"),
  HomeTimelineIcon5: require("../assets/images/home/home-timeline-icon5.png"),
  ContactMapPic: require("../assets/images/home/contact-map-pic.png"),
  PricingBuild: require("../assets/images/home/pricing-build-img.png"),
  PricingBuildLi: require("../assets/images/home/pricing-built-li.png"),
  PricingInnerTab1: require("../assets/images/home/pricing-inner-tab1.png"),
  PricingInnerTab2: require("../assets/images/home/pricing-inner-tab2.png"),
  PricingInnerTab3: require("../assets/images/home/pricing-inner-tab3.png"),
  PricingInnerTab4: require("../assets/images/home/pricing-inner-tab4.png"),
  PricingInnerTab3Vector: require("../assets/images/home/pricing-inner-tab3-vector.png"),
  PricingInnerTab3Input: require("../assets/images/home/pricing-inner-tab3-input.png"),
  PricingInnerTab4Canada: require("../assets/images/home/pricing-inner-tab4-canada.png"),
  PricingInnerTab4Usa: require("../assets/images/home/pricing-inner-tab4-usa.png"),
  PricingInnerTab5img1: require("../assets/images/home/pricing-inner-tab5-img1.png"),
  PricingInnerTab5img2: require("../assets/images/home/pricing-inner-tab5-img2.png"),
  PricingInnerTab5img3: require("../assets/images/home/pricing-inner-tab5-img3.png"),
  PricingInnerTab5img4: require("../assets/images/home/pricing-inner-tab5-img4.png"),
  PricingInnerTab5img4: require("../assets/images/home/pricing-inner-tab5-img4.png"),
  CanadaPost: require("../assets/images/home/canada-post.png"),
};
