import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import NoLayout from "./layouts/NoLayout";
import MainLayout from "./layouts/MainLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const Home = lazy(() => import("./pages/Home"));
const WhoWeAre = lazy(() => import("./pages/WhoWeAre"));
const FuffillCalculator = lazy(() => import("./pages/FuffillCalculator"));
const FullfilmentServices = lazy(() =>
  import("./pages/services/FullfilmentServices")
);
const WarehousingServices = lazy(() =>
  import("./pages/services/WarehousingServices")
);
const AmazonPrep = lazy(() => import("./pages/services/AmazonPrep"));
const WalmartPrep = lazy(() => import("./pages/services/WalmartPrep"));
const IntegrationServices = lazy(() =>
  import("./pages/services/IntegrationServices")
);
const Pricing = lazy(() => import("./pages/Pricing"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const BlogDetail = lazy(() => import("./pages/blog/BlogDetail"));
const Contact = lazy(() => import("./pages/Contact"));
const Login = lazy(() => import("./pages/admin/Login"));
const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
const ChangePassword = lazy(() => import("./pages/admin/ChangePassword"));
const Blogs = lazy(() => import("./pages/admin/Blogs"));
const UserContact = lazy(() => import("./pages/admin/UserContact"));
const UserContactDetail = lazy(() => import("./pages/admin/UserContactDetail"));
const AddBlog = lazy(() => import("./pages/admin/AddBlog"));
const AdminBlogs = lazy(() => import("./pages/admin/AdminBlogs"));
const Banner = lazy(() => import("./pages/admin/Banner"));
const AddBanner = lazy(() => import("./pages/admin/AddBanner"));
const Profile = lazy(() => import("./pages/admin/Profile"));
const EditBanner = lazy(() => import("./pages/admin/UpdateBanner"));
const EditBlog = lazy(() => import("./pages/admin/EditBlog"));
const SubPages = lazy(() => import("./pages/admin/SubPages"));
const Pages = lazy(() => import("./pages/admin/Pages"));
const EditPage = lazy(() => import("./pages/admin/EditPage"));
const AddSubPage = lazy(() => import("./pages/admin/AddSubPage"));
const AddPage = lazy(() => import("./pages/admin/AddPage"));
const BlogCategories = lazy(() => import("./pages/admin/BlogCategories"));
const TitleDesc = lazy(() => import("./pages/admin/TitleDesc"));
const AddTitleDes = lazy(() => import("./pages/admin/AddTitleDes"));
const EditTitleDesc = lazy(() => import("./pages/admin/EditTitleDesc"));
const Register = lazy(() => import("./pages/admin/Register"));
const AllRegisterUsers = lazy(() => import("./pages/admin/AllRegisterUsers"));
const Faq = lazy(() => import("./pages/admin/Faq"));
const Logo = lazy(() => import("./pages/admin/Logo"));
const Subscriber = lazy(() => import("./pages/admin/Subscriber"));
const TileDesImg = lazy(() => import("./pages/admin/TileDesImg"));
const EditTitleDesImg = lazy(() => import("./pages/admin/EditTitleDesImg"));
const AddTitleDesImg = lazy(() => import("./pages/admin/AddTitleDesImg"));
const Setting = lazy(() => import("./pages/admin/Setting"));

function App() {
  AOS.init({
    once: false,
    duration: 2000,
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/about-fulfillment-services" element={<WhoWeAre />} />
            <Route
              path="/fullfillment-calculator"
              element={<FuffillCalculator />}
            />
            <Route
              path="/fulfillment-services"
              element={<FullfilmentServices />}
            />
            <Route
              path="/warehousing-and-fulfillment-center"
              element={<WarehousingServices />}
            />
            <Route path="/amazon-prep-services" element={<AmazonPrep />} />
            <Route path="/walmart-prep-services" element={<WalmartPrep />} />
            <Route path="/integrations" element={<IntegrationServices />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/:title" element={<BlogDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route element={<NoLayout />}>
            <Route path="/admin/login" element={<Login />} />
          </Route>

          <Route element={<DashboardLayout />}>
            <Route
              path="/admin/change-password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/register"
              element={
                <ProtectedRoute>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/blogs"
              element={
                <ProtectedRoute>
                  <Blogs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/user-contact"
              element={
                <ProtectedRoute>
                  <UserContact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/contact/:id"
              element={
                <ProtectedRoute>
                  <UserContactDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-blog"
              element={
                <ProtectedRoute>
                  <AddBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/blog/:id"
              element={
                <ProtectedRoute>
                  <EditBlog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/banner"
              element={
                <ProtectedRoute>
                  <Banner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-banner"
              element={
                <ProtectedRoute>
                  <AddBanner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/banner/:id"
              element={
                <ProtectedRoute>
                  <EditBanner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/sub-pages"
              element={
                <ProtectedRoute>
                  <SubPages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/pages"
              element={
                <ProtectedRoute>
                  <Pages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/page/:id"
              element={
                <ProtectedRoute>
                  <EditPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-page"
              element={
                <ProtectedRoute>
                  <AddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/blog-categories"
              element={
                <ProtectedRoute>
                  <BlogCategories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description"
              element={
                <ProtectedRoute>
                  <TitleDesc />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description/add"
              element={
                <ProtectedRoute>
                  <AddTitleDes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description/edit/:id"
              element={
                <ProtectedRoute>
                  <EditTitleDesc />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/all-users"
              element={
                <ProtectedRoute>
                  <AllRegisterUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/all-faq"
              element={
                <ProtectedRoute>
                  <Faq />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/all-logo"
              element={
                <ProtectedRoute>
                  <Logo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/subscriber"
              element={
                <ProtectedRoute>
                  <Subscriber />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description-image"
              element={
                <ProtectedRoute>
                  <TileDesImg />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description-image/edit/:id"
              element={
                <ProtectedRoute>
                  <EditTitleDesImg />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/title-description-image/add"
              element={
                <ProtectedRoute>
                  <AddTitleDesImg />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/setting"
              element={
                <ProtectedRoute>
                  <Setting />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
        <ScrollToTop />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
