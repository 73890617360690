import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa6";

const MoveToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      const viewportHeight = window.innerHeight;
      if (currentScroll >= viewportHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className="movetotop"
      style={{ display: isVisible ? "flex" : "none", cursor: "pointer" }}
      onClick={() => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      }}
    >
      <a style={{ cursor: "pointer" }}>
        <FaArrowUp />
      </a>
    </div>
  );
};

export default MoveToTop;
