import React, { useContext } from "react";
import { IMG } from "../assets/img";
import { Link, NavLink } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn, FaQuora, FaYoutube } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import HeaderPhone from "./HeaderPhone";
import { MdKeyboardArrowDown } from "react-icons/md";
import GlobalContext from "../context/globalContext";

const Header = ({ setMenuopen, menuopen }) => {
  const { settingsData } = useContext(GlobalContext);

  return (
    <>
      <header>
        <NavContent setMenuopen={setMenuopen} menuopen={menuopen} />
      </header>
      <div className="header-phone-main-sec">
        <div className="header-top">
          <Link to={settingsData[0]?.twitter} target="blank">
            <FaXTwitter />
          </Link>
          <Link to={settingsData[0]?.facebook} target="blank">
            <FaFacebookF />
          </Link>
          <Link to={settingsData[0]?.instagram} target="blank">
            <IoLogoInstagram />
          </Link>
          <Link to={settingsData[0]?.linkedin} target="blank">
            <FaLinkedinIn />
          </Link>
          <Link to={settingsData[0]?.youtube} target="blank">
            <FaYoutube />
          </Link>
          <Link to={settingsData[0]?.quora} target="blank">
            <FaQuora />
          </Link>
        </div>
        <div className="header-phone">
          <Link to={"/"} className="header-logo">
            <img src={IMG.HeaderLogo} alt="logo" />
          </Link>
          <button
            className="header-menu-button"
            onClick={() => setMenuopen(!menuopen)}
          >
            {menuopen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.33em"
                height="1.33em"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            ) : (
              <AiOutlineMenu />
            )}
          </button>
        </div>
        <HeaderPhone menuopen={menuopen} setMenuopen={setMenuopen} />
      </div>
    </>
  );
};

export default Header;

const NavContent = ({ setMenuopen, menuopen }) => {
  const { settingsData, contextLoading } = useContext(GlobalContext);

  return (
    <>
      <Link to={"/"} className="header-logo">
        <img src={settingsData[0]?.logo?.url} alt="logo" />
      </Link>
      <div className="header-main-sec">
        <div className="header-top">
          <Link to={settingsData[0]?.twitter} target="blank">
            <FaXTwitter />
          </Link>
          <Link to={settingsData[0]?.facebook} target="blank">
            <FaFacebookF />
          </Link>
          <Link to={settingsData[0]?.instagram} target="blank">
            <IoLogoInstagram />
          </Link>
          <Link to={settingsData[0]?.linkedin} target="blank">
            <FaLinkedinIn />
          </Link>
          <Link to={settingsData[0]?.youtube} target="blank">
            <FaYoutube />
          </Link>
          <Link to={settingsData[0]?.quora} target="blank">
            <FaQuora />
          </Link>
        </div>
        <div className="header-links">
          {/* <NavLink className="header-link" to={"/"}>
            Home
          </NavLink>
          <NavLink className="header-link" to={"/about-fulfillment-services"}>
            About Us
          </NavLink> */}

          <div className="dropdown-button">
            Our Services <MdKeyboardArrowDown />
            <ul className="dropdown-items">
              <li>
                <NavLink
                  onClick={() => setMenuopen(false)}
                  className="header-link"
                  to={"/fulfillment-services"}
                >
                  Fulfillment Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMenuopen(false)}
                  className="header-link"
                  to={"/warehousing-and-fulfillment-center"}
                >
                  Warehousing Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMenuopen(false)}
                  className="header-link"
                  to={"/amazon-prep-services"}
                >
                  Amazon Prep Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMenuopen(false)}
                  className="header-link"
                  to={"/walmart-prep-services"}
                >
                  Walmart Prep Services
                </NavLink>
              </li>
            </ul>
          </div>

          <NavLink className="header-link" to={"/integrations"}>
            Integrations
          </NavLink>
          <NavLink className="header-link" to={"#"}>
            Pricing
          </NavLink>
          {/* <NavLink className="header-link" to={"/blog"}>
            Blog
          </NavLink> */}
          <NavLink className="header-link" to={"/contact"}>
            Contact Us
          </NavLink>

          <Link
            to={"https://app.extensiv.com/login"}
            className="client-login"
            target="blank"
          >
            Client Login
          </Link>
        </div>
      </div>
    </>
  );
};
