import React from "react";
import { IMG } from "../assets/img";

const PrivacyPolicy = () => {
  return (
    <>
      <section
        className="privacy-banner"
        style={{ backgroundImage: `url(${IMG.PrivacyBanner})` }}
      >
        <h1>Privacy Policy</h1>
      </section>

      <section className="container privacy-content">
        <div className="wpb_wrapper">
          <h2>
            <strong>Titan Fulfillment Services Privacy Policy</strong>
          </h2>

          <p>
            At Titan Fulfillment Services ("Titan", "we", "us", "our"), we are
            committed to protecting your personal data. This Privacy Policy
            outlines how we collect, use, and safeguard your personal
            information when you engage with our warehousing and fulfillment
            services through our website or any other platforms.
          </p>

          <h4>Information We Collect</h4>
          <p>
            We may collect the following types of personal data to provide our
            services:
          </p>
          <ul>
            <li>
              Contact details such as your name, email address, and phone
              number.
            </li>
            <li>
              Business details including company name, billing, and shipping
              addresses.
            </li>
            <li>Payment information for processing transactions.</li>
            <li>
              Data related to your orders, including product information,
              delivery requirements, and tracking details.
            </li>
            <li>
              Website usage data for analytics and improving our services.
            </li>
          </ul>

          <h4>How We Use Your Information</h4>
          <p>We use the personal information we collect to:</p>
          <ul>
            <li>Process orders and deliver products.</li>
            <li>Provide customer support and respond to inquiries.</li>
            <li>Manage payments and financial transactions.</li>
            <li>Ensure the security of your data and our systems.</li>
            <li>Improve our warehousing and fulfillment services.</li>
            <li>
              Comply with legal obligations, including tax and accounting
              regulations.
            </li>
          </ul>

          <h4>Sharing Your Information</h4>
          <p>
            Titan Fulfillment Services may share your personal data with third
            parties in certain situations:
          </p>
          <ul>
            <li>
              With service providers, such as shipping companies or payment
              processors, to complete transactions.
            </li>
            <li>
              With business partners who help us provide our services, ensuring
              they comply with strict confidentiality obligations.
            </li>
            <li>
              In response to legal requests or to comply with regulatory
              requirements.
            </li>
          </ul>

          <h4>Data Security</h4>
          <p>
            We take the security of your personal data seriously. Titan
            Fulfillment Services employs industry-standard security measures to
            protect your data from unauthorized access, disclosure, alteration,
            or destruction.
          </p>

          <h4>Retention of Personal Data</h4>
          <p>
            We retain your personal information for as long as necessary to
            fulfill the purposes described in this Privacy Policy or as required
            by law. If you have an account with us, your data may be retained
            for the duration of your account's existence.
          </p>

          <h4>Your Rights</h4>
          <p>You have the right to:</p>
          <ul>
            <li>Access the personal data we hold about you.</li>
            <li>Request corrections to inaccurate data.</li>
            <li>
              Request the deletion of your personal data under certain
              conditions.
            </li>
            <li>
              Object to the processing of your data in specific situations.
            </li>
          </ul>

          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns regarding this Privacy Policy,
            or if you would like to exercise your rights, please contact us at
            <a href="mailto:privacy@titanfulfillment.com">
              {" "}
              info@titanfulfillment.com{" "}
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
