import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaCheck,
  FaMobileRetro,
  FaLocationDot,
} from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import GlobalContext from "../context/globalContext";
import Loader from "../components/Loader";

const Footer = () => {
  const { contextLoading } = useContext(GlobalContext);
  return (
    <>
      {contextLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container footer-contact-sec">
            <div className="footer-contact-top">
              <h3>Get best pricing for your eCommerce Orders</h3>
            </div>
            <p>
              24x7 customer support and expert advice. Up to 70% savings on
              shipping costs with all major carriers
            </p>
            <div className="footer-contact-num">
              <Link to={"/pricing"}>
                Get Best Pricing
                <span>
                  <FaCheck />
                </span>
              </Link>
            </div>
          </div>

          <footer className="fluid-container">
            <div className="footer-content">
              <article>
                <h3>About Titan</h3>
                <div>
                  <div>
                    <Link to={"/about-fulfillment-services"}>
                      <FaArrowRight /> &nbsp; About Us
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Affiliate
                    </Link>
                  </div>
                  <div>
                    <Link to={"/fullfillment-calculator"}>
                      <FaArrowRight /> &nbsp; Calculator
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Press
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Terms & Conditions
                    </Link>
                  </div>
                  <div>
                    <Link to={"/privacy-policy"}>
                      <FaArrowRight /> &nbsp; Privacy Policy
                    </Link>
                  </div>
                </div>
              </article>
              <section>
                <h3>Services</h3>
                <article>
                  <div>
                    <div>
                      <Link to={"/fulfillment-services"}>
                        <FaArrowRight /> &nbsp; Fulfillment Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/warehousing-and-fulfillment-center"}>
                        <FaArrowRight /> &nbsp; Warehousing Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/amazon-prep-services"}>
                        <FaArrowRight /> &nbsp; Amazon Prep Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/walmart-prep-services"}>
                        <FaArrowRight /> &nbsp; Walmart Prep Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/integrations"}>
                        <FaArrowRight /> &nbsp; Our Integrations
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Import/Export
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Link to={"/fulfillment-services"}>
                        <FaArrowRight /> &nbsp; Fulfillment Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/warehousing-and-fulfillment-center"}>
                        <FaArrowRight /> &nbsp; Warehousing Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/amazon-prep-services"}>
                        <FaArrowRight /> &nbsp; Amazon Prep Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/walmart-prep-services"}>
                        <FaArrowRight /> &nbsp; Walmart Prep Services
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"/integrations"}>
                        <FaArrowRight /> &nbsp; Our Integrations
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Import/Export
                      </Link>
                    </div>
                  </div>
                </article>
              </section>
              <section>
                <h3>Integration Partners</h3>
                <article>
                  <div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Woocommerce
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Etsy
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Returnly
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Magento
                      </Link>
                    </div>{" "}
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Ebay
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Opencart
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Amazon
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Walmart
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; shopify
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; BigCommerce
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; TikTok Shop
                      </Link>
                    </div>
                    <div>
                      <Link to={"#"}>
                        <FaArrowRight /> &nbsp; Market Places
                      </Link>
                    </div>
                  </div>
                </article>
              </section>
            </div>

            <div className="row footer-middle-container">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                <div className="footer-middle-section">
                  <div>
                    <FaEnvelope />
                  </div>
                  <div>
                    <p>E-Mail</p>
                    <Link to="mail-to:info@titanfulfillment.com">
                      info@titanfulfillment.com
                    </Link>
                  </div>
                </div>
              </div>{" "}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                <div className="footer-middle-section">
                  <div>
                    <IoCall />
                  </div>
                  <div>
                    <p>Support 24x7</p>
                    <Link>+1(844) 601-2444</Link>
                  </div>
                </div>
              </div>{" "}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                <div className="footer-middle-section">
                  <div>
                    <FaLocationDot />
                  </div>
                  <div>
                    <p>Our Locations</p>
                    <Link>New York NY, Marlboro NJ, Houston TX</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-bottom-container">
              <p>© 2024 Titan Fulfillment / All Rights Reserved</p>
              <Link to="/contact">Sitemap / Contact Us</Link>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
